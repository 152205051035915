/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/init-declarations */

import { ProcessType, isReleaseResource, isRunbookSnapshotResource } from "@octopusdeploy/octopus-server-client";
import type { LibraryVariableSetResource, ProjectResource, ISnapshotResource, ScopeValues, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import { flatten } from "lodash";
import * as React from "react";
import { Action, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import mergeScopeValues from "~/areas/variables/MergeScopeValues";
import { FilterableVariableDisplayer } from "~/areas/variables/VariableDisplayer/FilterableVariableDisplayer";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { Section } from "~/components/Section/Section";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import Note from "~/primitiveComponents/form/Note/Note";
import type { VariableWithSource } from "../../../../variables/VariableDisplayer";
import { convertVariableResourcesToVariablesWithSource } from "../../../../variables/convertVariableResourcesToVariablesWithSource";
import { GitRefChip } from "../GitRefChip/GitRefChip";
import { UpdateVariablesDialog } from "../UpdateVariables/UpdateVariables";

interface VariableSnapshotProps {
    projectId: string;
    snapshot: ISnapshotResource;
    doBusyTask: DoBusyTask;
    updateVariablesRefreshKey: string;
    onUpdate?(): void;
}

interface VariableSnapshotInternalProps {
    actionDispatch: AnalyticActionDispatcher;
    trackedActionDispatch: AnalyticTrackedActionDispatcher;
}

interface VariableSnapshotState {
    model?: Model;
    showConfirmationDialog: boolean;
    showVariables: boolean;
}

interface Model {
    readonly project: ProjectResource;
    readonly snapshot: ISnapshotResource;
    readonly projectVariables: VariableSetResource;
    readonly libraryVariableSetVariables: ReadonlyArray<VariableSetResource>;
    readonly libraryVariableSets: ReadonlyArray<LibraryVariableSetResource>;
}

const GetProcessType = (snapshot: ISnapshotResource) => {
    if (isReleaseResource(snapshot)) {
        return ProcessType.Deployment;
    } else if (isRunbookSnapshotResource(snapshot)) {
        return ProcessType.Runbook;
    }

    return undefined;
};

const VariableSnapshotDescription: React.FC<{ snapshot: ISnapshotResource }> = ({ snapshot }) => {
    const processType = GetProcessType(snapshot);

    const snapshotTerm = processType === ProcessType.Deployment ? "release" : "snapshot";
    const owner = processType === ProcessType.Deployment ? "project" : "runbook";

    const snapshotGitRef = snapshot.VersionControlReference ?? snapshot.GitReference;
    const variablesGitRef = snapshotGitRef && snapshotGitRef.VariablesGitCommit && snapshotGitRef.GitRef && { GitRef: snapshotGitRef.GitRef, GitCommit: snapshotGitRef.VariablesGitCommit };

    return (
        <p>
            When this {snapshotTerm} was created, a snapshot of the project variables was taken
            {variablesGitRef && (
                <>
                    {" "}
                    from <GitRefChip vcsRef={variablesGitRef} />
                </>
            )}
            . You can overwrite the variable snapshot by re-importing the variables from the {owner}.
        </p>
    );
};

class VariableSnapshotInternal extends BaseComponent<VariableSnapshotProps & VariableSnapshotInternalProps, VariableSnapshotState> {
    constructor(props: VariableSnapshotProps & VariableSnapshotInternalProps) {
        super(props);
        this.state = { showConfirmationDialog: false, showVariables: false };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            await this.loadData(this.props.snapshot);
        });
    }

    async UNSAFE_componentWillReceiveProps(nextProps: VariableSnapshotProps) {
        if (nextProps.updateVariablesRefreshKey !== this.props.updateVariablesRefreshKey) {
            await this.props.doBusyTask(async () => {
                await this.loadData(nextProps.snapshot);
            });
        }
    }

    componentDidUpdate(_: VariableSnapshotProps & VariableSnapshotInternalProps, prevState: VariableSnapshotState) {
        if (!prevState.showConfirmationDialog && this.state.showConfirmationDialog) {
            const ev: ActionEvent = {
                action: Action.Update,
                resource: "Release",
            };
            this.props.actionDispatch("Start Updating Variables", ev);
        }

        if (!prevState.showVariables && this.state.showVariables) {
            const ev: ActionEvent = {
                action: Action.View,
                resource: "Release",
            };
            this.props.actionDispatch("View Variable Snapshot", ev);
        }
    }

    private async updateVariablesWithAnalytics() {
        const ev: ActionEvent = {
            action: Action.Update,
            resource: "Release",
        };

        await this.props.trackedActionDispatch("Update Variables", ev, async () => {
            await this.updateVariables();
        });
    }

    render() {
        const hasVariables = this.getVariables().length > 0;
        const showHideSnapshot = (
            <ActionButton
                label={this.state.showVariables ? "Hide Snapshot" : "Show Snapshot"}
                type={ActionButtonType.Ternary}
                onClick={() => {
                    this.setState({ showVariables: !this.state.showVariables });
                }}
            />
        );
        const updateSnapshot = (
            <ActionButton
                label="Update variables"
                onClick={() => {
                    this.setState({ showConfirmationDialog: true });
                }}
            />
        );

        const processType = GetProcessType(this.props.snapshot);

        return (
            <div>
                <FormSectionHeading title="Variable Snapshot" />
                <Section sectionHeader="">
                    <Note>
                        <VariableSnapshotDescription snapshot={this.state.model?.snapshot ?? this.props.snapshot} />
                        {hasVariables ? (
                            <ActionList actions={[showHideSnapshot, updateSnapshot]} />
                        ) : (
                            <>
                                <p>No variable snapshot exists.</p>
                                <ActionList actions={[updateSnapshot]} />
                            </>
                        )}
                    </Note>
                </Section>
                {this.state.showVariables && (
                    <div>
                        <FilterableVariableDisplayer availableScopes={this.availableScopes} variableSections={[this.getVariables()]} doBusyTask={this.props.doBusyTask} />
                    </div>
                )}
                <UpdateVariablesDialog
                    open={this.state.showConfirmationDialog}
                    close={() => this.setState({ showConfirmationDialog: false })}
                    processType={processType!}
                    onUpdateVariablesClicked={async () => {
                        await this.updateVariablesWithAnalytics();
                    }}
                />
            </div>
        );
    }

    private async loadData(snapshot: ISnapshotResource) {
        const project = await repository.Projects.get(this.props.projectId);

        let snapshotVariables: VariableSetResource[];
        if (isReleaseResource(snapshot)) {
            snapshotVariables = await repository.Releases.variables(snapshot);
        } else if (isRunbookSnapshotResource(snapshot)) {
            snapshotVariables = await repository.RunbookSnapshots.variables(snapshot);
        } else {
            throw new Error("Unknown type of snapshot resource");
        }
        const projectVariableSet = snapshotVariables.find((vs) => vs.OwnerId === project.Id);
        const libraryVariableSetVariables = snapshotVariables.filter((vs) => vs.OwnerId !== project.Id);
        const libraryVariableSets = await repository.LibraryVariableSets.all({ ids: libraryVariableSetVariables.map((v) => v!.OwnerId) }!);
        this.setState({
            model: {
                project: project!,
                snapshot,
                projectVariables: projectVariableSet!,
                libraryVariableSetVariables,
                libraryVariableSets,
            },
        });
    }

    private getVariables(): ReadonlyArray<VariableWithSource> {
        if (!this.state.model) {
            return [];
        }

        const projectSource = {
            projectName: this.state.model.project.Name,
            projectId: this.props.projectId,
        };

        const projectVariables = convertVariableResourcesToVariablesWithSource(this.state.model.projectVariables.Variables, projectSource);
        const libraryVariables = flatten(
            this.state.model.libraryVariableSetVariables.map((vSet) => {
                const libraryVariableSet = this.state.model!.libraryVariableSets.find((s) => s.Id === vSet.OwnerId)!;
                const variableSetSource = {
                    variableSetName: libraryVariableSet.Name,
                    variableSetId: libraryVariableSet.Id,
                };
                return convertVariableResourcesToVariablesWithSource(vSet.Variables, variableSetSource);
            })
        );
        return [...projectVariables, ...libraryVariables];
    }

    private get availableScopes(): ScopeValues {
        const allScopeValues: ScopeValues[] = this.state.model ? [this.state.model.projectVariables.ScopeValues, ...this.state.model.libraryVariableSetVariables.map((set) => set.ScopeValues)] : [];
        return mergeScopeValues(allScopeValues);
    }

    private async updateVariables() {
        if (isReleaseResource(this.props.snapshot)) {
            const updatedRelease = await repository.Releases.snapshotVariables(this.props.snapshot);
            await this.loadData(updatedRelease);
        } else if (isRunbookSnapshotResource(this.props.snapshot)) {
            const updateRunbookSnapshot = await repository.RunbookSnapshots.snapshotVariables(this.props.snapshot);
            await this.loadData(updateRunbookSnapshot);
        }
        this.setState({ showConfirmationDialog: false }, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate();
            }
        });
    }
}

export const VariableSnapshot: React.FC<VariableSnapshotProps> = (props) => {
    const actionDispatch = useAnalyticActionDispatch();
    const trackedActionDispatch = useAnalyticTrackedActionDispatch();

    return <VariableSnapshotInternal {...props} actionDispatch={actionDispatch} trackedActionDispatch={trackedActionDispatch} />;
};
